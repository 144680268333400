const mantrasData = [
    { 
      deity: "शिव ध्यान मंत्र", 
      mantras: [
       `ॐ मृत्युंजय परेशान जगदाभयनाशन ।
        तव ध्यानेन देवेश मृत्युप्राप्नोति जीवती ।।\n
        वन्दे ईशान देवाय नमस्तस्मै पिनाकिने ।
        आदिमध्यांत रूपाय मृत्युनाशं करोतु मे ।।\n
        नमस्तस्मै भगवते कैलासाचल वासिने ।
        नमोब्रह्मेन्द्र रूपाय मृत्युनाशं करोतु मे ।।\n
        त्र्यंबकाय नमस्तुभ्यं पंचस्याय नमोनमः ।
        नमो दोर्दण्डचापाय मम मृत्युम् विनाशय ।।\n
        नमोर्धेन्दु स्वरूपाय नमो दिग्वसनाय च ।
        नमो भक्तार्ति हन्त्रे च मम मृत्युं विनाशय ।।\n
        देवं मृत्युविनाशनं भयहरं साम्राज्य मुक्ति प्रदम् ।
        नाना भूतगणान्वितं दिवि पदैः देवैः सदा सेवितम् ।।\n
        अज्ञानान्धकनाशनं शुभकरं विध्यासु सौख्य प्रदम् ।
        सर्व सर्वपति महेश्वर हरं मृत्युंजय भावये ।।`,

        `ॐ नमस्ते अस्तु भगवान विश्वेश्वराय महादेवाय
        त्रयम्बकाये त्रिपुरान्तकाय त्रिकाग्नि कालाये
        कालाग्नि रुद्राए नीलकंठाय मृत्युंजयाय
        सर्वेश्वराय सदाशिवाय श्रीमन् महादेवाय नमः`,

        `ॐ वन्दे देव उमापतिं सुरगुरुं, वन्दे जगत्कारणम् ।
        वन्दे पन्नगभूषणं मृगधरं, वन्दे पशूनां पतिम् ॥
        वन्दे सूर्य शशांक वह्नि नयनं, वन्दे मुकुन्दप्रियम् ।
        वन्दे भक्त जनाश्रयं च वरदं, वन्दे शिवंशंकरम् ॥`
      ]
    },
    { 
      deity: "विष्णु ध्यान मंत्र", 
      mantras: [
       `शान्ताकारं भुजगशयनं पद्मनाभं सुरेशं
        विश्वाधारं गगनसदृशं मेघवर्ण शुभाङ्गम् ।
        लक्ष्मीकान्तं कमलनयनं योगिभिर्ध्यानगम्यम्
        वन्दे विष्णुं भवभयहरं सर्वलोकैकनाथम् ॥`,

        `शुक्लाम्बरधरं विष्णुं शशिवर्णं चतुर्भुजम् ।
         प्रसन्नवदनं ध्यायेत् सर्वविघ्नोपशान्तये ॥`,

        `सहस्रशीर्षा पुरुषः सहस्राक्षः सहस्रपात् ।
        स भूमिं विश्वतो वृत्वात्यतिष्ठद्दशाङुलम् ॥१॥\n
        पुरुष एवेदं सर्वं यद्भूतं यच्च भव्यम् ।
        उतामृतत्वस्येशानो यदन्नेनातिरोहति ॥२॥\n
        एतावानस्य महिमातो ज्यायाँश्च पूरुषः ।
        पादोऽस्य विश्वा भूतानि त्रिपादस्यामृतं दिवि ॥३॥\n
        त्रिपादूर्ध्व उदैत्पूरुषः पादोऽस्येहाभवत्पुनः ।
        ततो विष्वङ् व्यक्रामत्साशनानशने अभि ॥४॥\n
        तस्माद्विराळजायत विराजो अधि पूरुषः ।
        स जातो अत्यरिच्यत पश्चाद्भूमिमथो पुरः ॥५॥\n
        यत्पुरुषेण हविषा देवा यज्ञमतन्वत ।
        वसन्तो अस्यासीदाज्यं ग्रीष्म इध्मः शरद्धविः ॥६॥\n
        तं यज्ञं बर्हिषि प्रौक्षन्पुरुषं जातमग्रतः ।
        तेन देवा अयजन्त साध्या ऋषयश्च ये ॥७॥\n
        तस्माद्यज्ञात्सर्वहुतः सम्भृतं पृषदाज्यम् ।
        पशून्ताँश्चक्रे वायव्यानारण्यान् ग्राम्याश्च ये ॥८॥\n
        तस्माद्यज्ञात्सर्वहुत ऋचः सामानि जज्ञिरे ।
        छन्दांसि जज्ञिरे तस्माद्यजुस्तस्मादजायत ॥९॥\n
        तस्मादश्वा अजायन्त ये के चोभयादतः ।
        गावोः ह जज्ञिरे तस्मात् तस्माज्जाता अजावयः ॥१०॥\n
        यत्पुरुषं व्यदधुः कतिधा व्यकल्पयन् ।
        मुखं किमस्य कौ बाहू का ऊरू पादा उच्येते ॥११॥\n
        ब्राह्मणोऽस्य मुखमासीद् बाहू राजन्यः कृतः ।
        ऊरू तदस्य यद्वैश्यः पद्भ्यां शूद्रो अजायत ॥१२॥\n
        चन्द्रमा मनसो जातश्चक्षोः सूर्यो अजायत ।
        मुखादिन्द्रश्चाग्निश्च प्राणाद्वायुरजायत ॥१३॥\n
        नाभ्या आसीदन्तरिक्षं शीर्ष्णो द्यौः समवर्तत ।
        पद्भ्यां भूमिर्दिशः श्रोत्रात्तथा लोकाँ अकल्पयन् ॥१४॥\n
        सप्तास्यासन् परिधयस्त्रिः सप्त समिधः कृताः ।
        देवा यद्यज्ञं तन्वाना अबध्नन्पुरुषं पशुम् ॥१५॥\n
        यज्ञेन यज्ञमयजन्त देवास्तानि धर्माणि प्रथमान्यासन् ।
        ते ह नाकं महिमानः सचन्त यत्र पूर्वे साध्याः सन्ति देवाः ॥१६॥`
      ]
    },
    {deity:"हनुमान ध्यान मंत्र",
     mantras: [
        `मनोजवं मारुततुल्यवेगं जितेन्द्रियं बुद्धिमतां वरिष्ठम् ।
        वातात्मजं वानरयूथमुख्यं श्रीरामदूतं शरणं प्रपद्ये ॥`,

        `ॐ नमो हनुमते रुद्रावताराय सर्वशत्रुसंहारणाय।
        सर्वरोग हराय सर्ववशीकरणाय रामदूताय स्वाहा॥`,

        `ॐ आञ्जनेयाय विद्महे वायुपुत्राय धीमहि।
        तन्नो हनुमत् प्रचोदयात्॥`
     ]

    },
    { 
      deity: "दुर्गा माँ", 
      mantras: [
       `सर्वमंगल मांगल्ये शिवे सवार्थ साधिके
        शरण्येत्र्यंबके गौरी नारायणी नमोस्तुते`,

        `आगच्छ वरदे देवि दैत्य-दर्प-निषूदिनि
          पूजां गृहाण सुमुखि नमस्ते शंकरप्रिये`
      ]
    },
    { 
        deity: "लक्ष्मी माँ", 
        mantras: [
         `सर्वलोकस्य जननीं सर्वसौख्यप्रदायिनीम्।
         सर्वदेवमयीमीशां देवीमावाहयाम्यहम्।।:`
        ]
      },
    
    { 
      deity: "गणेश जी", 
      mantras: [
       `गजाननं भूत गणादि सेवितं,
        कपित्थ जम्बू फल चारू भक्षणम् ।
        उमासुतं शोक विनाशकारकम्,
        नमामि विघ्नेश्वर पाद पंकजम् ॥`,

        `ॐ गणानां त्वा गणपति (गूं) हवामहे
        प्रियाणां त्वा प्रियपति (गूं) हवामहे
        निधिनां त्वा निधिपति (गूं) हवामहे
        वसो मम आहमजानि गर्भधमा त्वम जासि गर्भधम्।`
      ]
    },
    { 
        deity: "विश्वकर्मा जी", 
        mantras: [
         `नमामि विश्वकर्माणं द्विभुजं विश्ववन्दितम्।
         गृहवास्तुविधातारं महाबलपराक्रमम्।।
        प्रसीद विश्वकर्मस्त्वं शिल्पविद्याविशारद।
        दण्डपाणे ! नमस्तुभ्यं तेजोमूर्तिधर प्रभो!`
        ]
      },
  ];

  export default mantrasData

  
export const devStuti = [
    {
        title:`॥ शिवतांडवस्तोत्र ॥`,
        description:`जटा टवी गलज्जलप्रवाह पावितस्थले गलेऽव लम्ब्यलम्बितां भुजंगतुंग मालिकाम्‌।
                    डमड्डमड्डमड्डमन्निनाद वड्डमर्वयं चकारचण्डताण्डवं तनोतु नः शिव: शिवम्‌ ॥१॥\n
                    जटाकटा हसंभ्रम भ्रमन्निलिंपनिर्झरी विलोलवीचिवल्लरी विराजमानमूर्धनि।
                    धगद्धगद्धगज्ज्वल ल्ललाटपट्टपावके किशोरचंद्रशेखरे रतिः प्रतिक्षणं मम: ॥२॥\n
                    धराधरेंद्रनंदिनी विलासबन्धुबन्धुर स्फुरद्दिगंतसंतति प्रमोद मानमानसे।
                    कृपाकटाक्षधोरणी निरुद्धदुर्धरापदि क्वचिद्विगम्बरे मनोविनोदमेतु वस्तुनि ॥३॥\n
                    जटाभुजंगपिंगल स्फुरत्फणामणिप्रभा कदंबकुंकुमद्रव प्रलिप्तदिग्व धूमुखे।
                    मदांधसिंधु रस्फुरत्वगुत्तरीयमेदुरे मनोविनोदद्भुतं बिंभर्तुभूत भर्तरि ॥४॥\n
                    सहस्रलोचन प्रभृत्यशेषलेखशेखर प्रसूनधूलिधोरणी विधूसरां घ्रिपीठभूः।
                    भुजंगराजमालया निबद्धजाटजूटकः श्रियैचिरायजायतां चकोरबंधुशेखरः ॥५॥\n
                    ललाटचत्वरज्वल द्धनंजयस्फुलिंगभा निपीतपंच सायकंनम न्निलिंपनायकम्‌।
                    सुधामयूखलेखया विराजमानशेखरं महाकपालिसंपदे शिरोजटालमस्तुनः ॥६॥\n
                    करालभालपट्टिका धगद्धगद्धगज्ज्वल द्धनंजया धरीकृतप्रचंड पंचसायके।
                    धराधरेंद्रनंदिनी कुचाग्रचित्रपत्र कप्रकल्पनैकशिल्पिनी त्रिलोचनेरतिर्मम ॥७॥\n
                    नवीनमेघमंडली निरुद्धदुर्धरस्फुर त्कुहुनिशीथनीतमः प्रबद्धबद्धकन्धरः।
                    निलिम्पनिर्झरीधरस्तनोतु कृत्तिसिंधुरः कलानिधानबंधुरः श्रियं जगंद्धुरंधरः ॥८॥\n     
                    प्रफुल्लनीलपंकज प्रपंचकालिमप्रभा विडंबि कंठकंध रारुचि प्रबंधकंधरम्‌।
                    स्मरच्छिदं पुरच्छिंद भवच्छिदं मखच्छिदं गजच्छिदांधकच्छिदं तमंतकच्छिदं भजे ॥९॥\n
                    अखर्वसर्वमंगला कलाकदम्बमंजरी रसप्रवाह माधुरी विजृंभणा मधुव्रतम्‌।
                    स्मरांतकं पुरातकं भावंतकं मखांतकं गजांतकांधकांतकं तमंतकांतकं भजे ॥१०॥\n
                    जयत्वदभ्रविभ्रम भ्रमद्भुजंगमस्फुरद्ध गद्धगद्विनिर्गमत्कराल भाल हव्यवाट्।
                    धिमिद्धिमिद्धि मिध्वनन्मृदंग तुंगमंगलध्वनिक्रमप्रवर्तित: प्रचण्ड ताण्डवः शिवः ॥११॥\n
                    दृषद्विचित्रतल्पयो र्भुजंगमौक्तिकमस्र जोर्गरिष्ठरत्नलोष्ठयोः सुहृद्विपक्षपक्षयोः।
                    तृणारविंदचक्षुषोः प्रजामहीमहेन्द्रयोः समं प्रवर्तयन्मनः कदा सदाशिवं भजे ॥१२॥\n
                    कदा निलिंपनिर्झरी निकुंजकोटरे वसन्‌ विमुक्तदुर्मतिः सदा शिरःस्थमंजलिं वहन्‌।
                    विमुक्तलोललोचनो ललामभाललग्नकः शिवेति मंत्रमुच्चरन्‌ कदा सुखी भवाम्यहम्‌ ॥१३॥\n
                    इमं हि नित्यमेव मुक्तमुक्तमोत्तम स्तवं पठन्स्मरन्‌ ब्रुवन्नरो विशुद्धमेति संततम्‌।
                    हरे गुरौ सुभक्तिमाशु याति नान्यथागतिं विमोहनं हि देहिनां सुशंकरस्य चिंतनम् ॥१६॥\n
                    पूजावसानसमये दशवक्त्रगीतं यः शम्भुपूजनपरं पठति प्रदोषे ।
                    तस्य स्थिरां रथगजेन्द्र तुरङ्गयुक्तां लक्ष्मीं सदैव सुमुखीं प्रददाति शम्भुः ॥ १५ ॥
                    `
    },
    {
        title:"॥ श्रीशिवपञ्चाक्षरस्तोत्रम् ॥",
        description:`नागेन्द्रहाराय त्रिलोचनाय भस्माङ्गरागाय महेश्वराय ।
                    नित्याय शुद्धाय दिगम्बराय, तस्मै न काराय नमः शिवाय ॥१॥\n
                    मन्दाकिनी सलिलचन्दन चर्चिताय नन्दीश्वर प्रमथनाथ महेश्वराय ।
                    मन्दारपुष्प बहुपुष्प सुपूजिताय तस्मै म काराय नमः शिवाय ॥२॥\n
                    शिवाय गौरीवदनाब्जवृन्द सूर्याय दक्षाध्वरनाशकाय । 
                    श्रीनीलकण्ठाय वृषध्वजाय तस्मै शि काराय नमः शिवाय ॥३॥ \n
                    वसिष्ठकुम्भोद्भवगौतमार्य मुनीन्द्रदेवार्चितशेखराय। 
                    चन्द्रार्क वैश्वानरलोचनाय तस्मै व काराय नमः शिवाय ॥४॥\n
                    यक्षस्वरूपाय जटाधराय पिनाकहस्ताय सनातनाय ।
                    दिव्याय देवाय दिगम्बराय तस्मै य काराय नमः शिवाय ॥५॥\n
                    पञ्चाक्षरमिदं पुण्यं यः पठेच्छिवसन्निधौ । 
                    शिवलोकमवाप्नोति शिवेन सह मोदते ॥\n
                    ॥ इति श्रीशिवपञ्चाक्षरस्तोत्रम् सम्पूर्णम् ॥`
    },
    {
        title: "।। शिव महिम्न स्तोत्रम् ।। ",
        description:`महिम्नः पारं ते परमविदुषो यद्यसदृशी, स्तुतिर्ब्रह्मादीनामपि तदवसन्नास्त्वयि गिरः।
                    अथाऽवाच्यः सर्वः स्वमतिपरिणामावधि गृणन् ममाप्येष स्तोत्रे हर निरपवादः परिकरः ।।\n
                    अतीतः पंथानं तव च महिमा वाङ्मनसयोः अतद्व्यावृत्त्या यं चकितमभिधत्ते श्रुतिरपि ।
                    स कस्य स्तोत्रव्यः कतिविधगुणः कस्य विषयः पदे त्वर्वाचीने पतति न मनः कस्य न वचः ।।\n
                    मधुस्फीता वाचः परमममृतं निर्मितवतः तव ब्रह्मन् किं वागपि सुरगुरोर्विस्मयपदम् ।
                    मम त्वेतां वाणीं गुणकथनपुण्येन भवतः पुनामीत्यर्थेऽस्मिन् पुरमथन बुद्धिर्व्यवसिता ।।\n
                    तवैश्वर्यं यत्तज्जगदुदयरक्षाप्रलयकृत् त्रयीवस्तु व्यस्तं तिस्रुषु गुणभिन्नासु तनुषु ।
                    अभव्यानामस्मिन् वरद रमणीयामरमणीं विहन्तुं व्याक्रोशीं विदधत इहैके जडधियः ।।\n
                    किमीहः किंकायः स खलु किमुपायस्त्रिभुवनं किमाधारो धाता सृजति किमुपादान इति च ।
                    अतर्क्यैश्वर्ये त्वय्यनवसर दुःस्थो हतधियः कुतर्कोऽयं कांश्चित् मुखरयति मोहाय जगतः ।।\n
                    अजन्मानो लोकाः किमवयववन्तोऽपि जगतां अधिष्ठातारं किं भवविधिरनादृत्य भवति ।
                    अनीशो वा कुर्याद् भुवनजनने कः परिकरो यतो मन्दास्त्वां प्रत्यमरवर संशेरत इमे ।।\n
                    त्रयी साङ्ख्यं योगः पशुपतिमतं वैष्णवमिति प्रभिन्ने प्रस्थाने परमिदमदः पथ्यमिति च ।
                    रुचीनां वैचित्र्यादृजुकुटिल नानापथजुषां नृणामेको गम्यस्त्वमसि पयसामर्णव इव ।।\n
                    महोक्षः खट्वाङ्गं परशुरजिनं भस्म फणिनः कपालं चेतीयत्तव वरद तन्त्रोपकरणम् ।
                    सुरास्तां तामृद्धिं दधति तु भवद्भूप्रणिहितां न हि स्वात्मारामं विषयमृगतृष्णा भ्रमयति ।।\n
                    ध्रुवं कश्चित् सर्वं सकलमपरस्त्वध्रुवमिदं परो ध्रौव्याऽध्रौव्ये जगति गदति व्यस्तविषये ।
                    समस्तेऽप्येतस्मिन् पुरमथन तैर्विस्मित इव स्तुवन् जिह्रेमि त्वां न खलु ननु धृष्टा मुखरता ।।\n
                    तवैश्वर्यं यत्नाद् यदुपरि विरिञ्चिर्हरिरधः परिच्छेतुं यातावनिलमनलस्कन्धवपुषः ।
                    ततो भक्तिश्रद्धा-भरगुरु-गृणद्भ्यां गिरिश यत् स्वयं तस्थे ताभ्यां तव किमनुवृत्तिर्न फलति ।।\n
                    अयत्नादासाद्य त्रिभुवनमवैरव्यतिकरं दशास्यो यद्बाहूनभृत-रणकण्डू-परवशान् ।
                    शिरःपद्मश्रेणी-रचितचरणाम्भोरुह-बलेः स्थिरायास्त्वद्भक्तेस्त्रिपुरहर विस्फूर्जितमिदम् ।।\n
                    अमुष्य त्वत्सेवा-समधिगतसारं भुजवनं बलात् कैलासेऽपि त्वदधिवसतौ विक्रमयतः ।
                    अलभ्यापातालेऽप्यलसचलितांगुष्ठशिरसि प्रतिष्ठा त्वय्यासीद् ध्रुवमुपचितो मुह्यति खलः ।।\n
                    यदृद्धिं सुत्राम्णो वरद परमोच्चैरपि सतीं अधश्चक्रे बाणः परिजनविधेयत्रिभुवनः ।
                    न तच्चित्रं तस्मिन् वरिवसितरि त्वच्चरणयोः न कस्याप्युन्नत्यै भवति शिरसस्त्वय्यवनतिः ।।\n
                    अकाण्ड-ब्रह्माण्ड-क्षयचकित-देवासुरकृपा विधेयस्याऽऽसीद् यस्त्रिनयन विषं संहृतवतः ।
                    स कल्माषः कण्ठे तव न कुरुते न श्रियमहो विकारोऽपि श्लाघ्यो भुवन-भय-भङ्ग-व्यसनिनः ।।\n
                    असिद्धार्था नैव क्वचिदपि सदेवासुरनरे निवर्तन्ते नित्यं जगति जयिनो यस्य विशिखाः ।
                    स पश्यन्नीश त्वामितरसुरसाधारणमभूत् स्मरः स्मर्तव्यात्मा न हि वशिषु पथ्यः परिभवः ।।\n
                    मही पादाघाताद् व्रजति सहसा संशयपदं पदं विष्णोर्भ्राम्यद् भुज-परिघ-रुग्ण-ग्रह-गणम् ।
                    मुहुर्द्यौर्दौस्थ्यं यात्यनिभृत-जटा-ताडित-तटा जगद्रक्षायै त्वं नटसि ननु वामैव विभुता ।।\n
                    वियद्व्यापी तारा-गण-गुणित-फेनोद्गम-रुचिः प्रवाहो वारां यः पृषतलघुदृष्टः शिरसि ते ।
                    जगद्द्वीपाकारं जलधिवलयं तेन कृतमिति अनेनैवोन्नेयं धृतमहिम दिव्यं तव वपुः ।।\n
                    रथः क्षोणी यन्ता शतधृतिरगेन्द्रो धनुरथो रथाङ्गे चन्द्रार्कौ रथ-चरण-पाणिः शर इति ।
                    दिधक्षोस्ते कोऽयं त्रिपुरतृणमाडम्बर विधिः विधेयैः क्रीडन्त्यो न खलु परतन्त्राः प्रभुधियः ।।\n
                    हरिस्ते सहस्रं कमल बलिमाधाय पदयोः यदेकोने तस्मिन् निजमुदहरन्नेत्रकमलम् ।
                    गतो भक्त्युद्रेकः परिणतिमसौ चक्रवपुषः त्रयाणां रक्षायै त्रिपुरहर जागर्ति जगताम् ।।\n
                    क्रतौ सुप्ते जाग्रत् त्वमसि फलयोगे क्रतुमतां क्व कर्म प्रध्वस्तं फलति पुरुषाराधनमृते ।
                    अतस्त्वां सम्प्रेक्ष्य क्रतुषु फलदान-प्रतिभुवं श्रुतौ श्रद्धां बध्वा दृढपरिकरः कर्मसु जनः ।।\n
                    क्रियादक्षो दक्षः क्रतुपतिरधीशस्तनुभृतां ऋषीणामार्त्विज्यं शरणद सदस्याः सुर-गणाः ।
                    क्रतुभ्रंशस्त्वत्तः क्रतुफल-विधान-व्यसनिनः ध्रुवं कर्तुं श्रद्धा विधुरमभिचाराय हि मखाः ।।\n
                    प्रजानाथं नाथ प्रसभमभिकं स्वां दुहितरं गतं रोहिद् भूतां रिरमयिषुमृष्यस्य वपुषा ।
                    धनुष्पाणेर्यातं दिवमपि सपत्राकृतममुं त्रसन्तं तेऽद्यापि त्यजति न मृगव्याधरभसः ।।\n
                    स्वलावण्याशंसा धृतधनुषमह्नाय तृणवत् पुरः प्लुष्टं दृष्ट्वा पुरमथन पुष्पायुधमपि ।
                    यदि स्त्रैणं देवी यमनिरत-देहार्ध-घटनात् अवैति त्वामद्धा बत वरद मुग्धा युवतयः ।।\n
                    श्मशानेष्वाक्रीडा स्मरहर पिशाचाः सहचराः चिता-भस्मालेपः स्रगपि नृकरोटी-परिकरः ।
                    अमङ्गल्यं शीलं तव भवतु नामैवमखिलं तथापि स्मर्तॄणां वरद परमं मङ्गलमसि ।।\n
                    मनः प्रत्यक् चित्ते सविधमविधायात्त-मरुतः प्रहृष्यद्रोमाणः प्रमद-सलिलोत्सङ्गति-दृशः । 
                    यदालोक्याह्लादं ह्रद इव निमज्यामृतमये दधत्यन्तस्तत्त्वं किमपि यमिनस्तत् किल भवान् ।।\n
                    त्वमर्कस्त्वं सोमस्त्वमसि पवनस्त्वं हुतवहः त्वमापस्त्वं व्योम त्वमु धरणिरात्मा त्वमिति च ।
                    परिच्छिन्नामेवं त्वयि परिणता बिभ्रति गिरं न विद्मस्तत्तत्त्वं वयमिह तु यत् त्वं न भवसि ।।\n 
                    त्रयीं तिस्रो वृत्तीस्त्रिभुवनमथो त्रीनपि सुरान् अकाराद्यैर्वर्णैस्त्रिभिरभिदधत् तीर्णविकृति ।
                    तुरीयं ते धाम ध्वनिभिरवरुन्धानमणुभिः समस्त-व्यस्तं त्वां शरणद गृणात्योमिति पदम् ।।\n
                    भवः शर्वो रुद्रः पशुपतिरथोग्रः सहमहान् तथा भीमेशानाविति यदभिधानाष्टकमिदम् ।
                    अमुष्मिन् प्रत्येकं प्रविचरति देव श्रुतिरपि प्रियायास्मैधाम्ने प्रणिहित-नमस्योऽस्मि भवते ।।\n
                    नमो नेदिष्ठाय प्रियदव दविष्ठाय च नमः, नमः क्षोदिष्ठाय स्मरहर महिष्ठाय च नमः ।
                    नमो वर्षिष्ठाय त्रिनयन यविष्ठाय च नमः, नमः सर्वस्मै ते तदिदमतिसर्वाय च नमः ।।\n
                    बहुल-रजसे विश्वोत्पत्तौ भवाय नमो नमः, प्रबल-तमसे तत् संहारे हराय नमो नमः । 
                    जन-सुखकृते सत्त्वोद्रिक्तौ मृडाय नमो नमः प्रमहसि पदे निस्त्रैगुण्ये शिवाय नमो नमः ।।\n
                    कृश-परिणति-चेतः क्लेशवश्यं क्व चेदं क्व च तव गुण-सीमोल्लङ्घिनी शश्वदृद्धिः ।
                    इति चकितममन्दीकृत्य मां भक्तिराधाद् वरद चरणयोस्ते वाक्य-पुष्पोपहारम् ।।\n
                    असित-गिरि-समं स्यात् कज्जलं सिन्धु-पात्रे सुर-तरुवर-शाखा लेखनी पत्रमुर्वी ।
                    लिखति यदि गृहीत्वा शारदा सर्वकालं तदपि तव गुणानामीश पारं न याति ।।\n
                    असुर-सुर-मुनीन्द्रैरर्चितस्येन्दु-मौलेः ग्रथित-गुणमहिम्नो निर्गुणस्येश्वरस्य ।
                    सकल-गण-वरिष्ठः पुष्पदन्ताभिधानः रुचिरमलघुवृत्तैः स्तोत्रमेतच्चकार ।।\n
                    अहरहरनवद्यं धूर्जटेः स्तोत्रमेतत् पठति परमभक्त्या शुद्ध-चित्तः पुमान् यः ।
                    स भवति शिवलोके रुद्रतुल्यस्तथाऽत्र प्रचुरतर-धनायुः पुत्रवान् कीर्तिमांश्च ।।\n
                    महेशान्नापरो देवो महिम्नो नापरा स्तुतिः अघोरान्नापरो मन्त्रो नास्ति तत्त्वं गुरोः परम् ।
                    दीक्षा दानं तपस्तीर्थं ज्ञानं योगादिकाः क्रियाः महिम्नस्तव पाठस्य कलां नार्हन्ति षोडशीम् ।।\n
                    कुसुमदशन-नामा सर्व-गन्धर्व-राजः शशिधरवर-मौलेर्देवदेवस्य दासः ।
                    स खलु निज-महिम्नो भ्रष्ट एवास्य रोषात् स्तवनमिदमकार्षीद् दिव्य-दिव्यं महिम्न ।।\n
                    सुरगुरुमभिपूज्य स्वर्ग-मोक्षैक-हेतुं पठति यदि मनुष्यः प्राञ्जलिर्नान्य-चेताः ।
                    व्रजति शिव-समीपं किन्नरैः स्तूयमानः स्तवनमिदममोघं पुष्पदन्तप्रणीतम् ।।\n
                    आसमाप्तमिदं स्तोत्रं पुण्यं गन्धर्व-भाषितम् अनौपम्यं मनोहारि सर्वमीश्वरवर्णनम् ।
                    इत्येषा वाङ्मयी पूजा श्रीमच्छङ्कर-पादयोः अर्पिता तेन देवेशः प्रीयतां मे सदाशिवः ।।\n
                    तव तत्त्वं न जानामि कीदृशोऽसि महेश्व यादृशोऽसि महादेव तादृशाय नमो नम ।
                    एककालं द्विकालं वा त्रिकालं यः पठेन्नरः सर्वपाप-विनिर्मुक्तः शिव लोके महीयते ।।\n
                    श्री पुष्पदन्त-मुख-पंकज-निर्गतेन स्तोत्रेण किल्बिष-हरेण हर-प्रियेण ।
                    कण्ठस्थितेन पठितेन समाहितेन सुप्रीणितो भवति भूतपतिर्महेशः ।।\n
                    ।। इति श्री पुष्पदंत विरचितं शिवमहिम्नः स्तोत्रं समाप्तम्।।`
    },
    {
        title: "॥ श्रीरुद्राष्टकम् ॥",
        description:`नमामीशमीशान निर्वाणरूपं, विभुं व्यापकं ब्रह्मवेदस्वरूपम् । 
                     निजं निर्गुणं निर्विकल्पं निरीहं चिदाकाशमाकाशवासं भजेऽहम् ॥ १॥\n
                     निराकारमोंकारमूलं तुरीयं गिरा ज्ञान गोतीतमीशं गिरीशम् । 
                     करालं महाकाल कालं कृपालं गुणागार संसारपारं नतोऽहम् ॥ २॥\n
                     तुषाराद्रि संकाश गौरं गभीरं मनोभूत कोटिप्रभा श्री शरीरम् । 
                     स्फुरन्मौलि कल्लोलिनी चारु गङ्गा लसद्भालबालेन्दु कण्ठे भुजङ्गा ॥ ३॥\n
                     चलत्कुण्डलं भ्रू सुनेत्रं विशालं प्रसन्नाननं नीलकण्ठं दयालम् ।
                     मृगाधीशचर्माम्बरं मुण्डमालं प्रियं शंकरं सर्वनाथं भजामि ॥ ४॥\n
                     प्रचण्डं प्रकृष्टं प्रगल्भं परेशं अखण्डं अजं भानुकोटिप्रकाशम् ।
                     त्रयः शूल निर्मूलनं शूलपाणिं भजेऽहं भवानीपतिं भावगम्यम् ॥ ५॥\n
                     कलातीत कल्याण कल्पान्तकारी सदा सज्जनानन्ददाता पुरारी ।
                     चिदानन्द संदोह मोहापहारी प्रसीद प्रसीद प्रभो मन्मथारी ॥ ६॥\n
                     न यावत् उमानाथ पादारविन्दं भजन्तीह लोके परे वा नराणाम् ।
                     न तावत् सुखं शान्ति सन्तापनाशं प्रसीद प्रभो सर्वभूताधिवासम् ॥ ७॥\n
                     न जानामि योगं जपं नैव पूजां नतोऽहं सदा सर्वदा शम्भु तुभ्यम् ।
                     जरा जन्म दुःखौघ तातप्यमानं प्रभो पाहि आपन्नमामीश शम्भो ॥ ८॥\n
                     रुद्राष्टकमिदं प्रोक्तं विप्रेण हरतोषये । ये पठन्ति नरा भक्त्या तेषां शम्भुः प्रसीदति ॥\n
                     ॥ इति श्रीगोस्वामितुलसीदासकृतं श्रीरुद्राष्टकं संपूर्णम् ॥\n`
    },
    {
        title:"॥ श्रीशिवाष्टकं ॥",
        description: `प्रभुं प्राणनाथं विभुं विश्वनाथं जगन्नाथनाथं सदानन्दभाजम् ।
                      भवद्भव्यभूतेश्वरं भूतनाथं शिवं शङ्करं शम्भुमीशानमीडे ॥ १॥\n
                      गले रुण्डमालं तनौ सर्पजालं महाकालकालं गणेशाधिपालम् ।
                      जटाजूटगङ्गोत्तरङ्गैर्विशालं शिवं शङ्करं शम्भुमीशानमीडे ॥ २॥\n
                      मुदामाकरं मण्डनं मण्डयन्तं महामण्डलं भस्मभूषाधरं तम् ।
                      अनादिह्यपारं महामोहहारं शिवं शङ्करं शम्भुमीशानमीडे ॥ ३॥\n
                      वटाधोनिवासं महाट्टाट्टहासं महापापनाशं सदासुप्रकाशम् ।
                      गिरीशं गणेशं महेशं सुरेशं शिवं शङ्करं शम्भुमीशानमीडे ॥ ४॥\n
                      गिरिन्द्रात्मजासंग्रहीतार्धदेहं गिरौ संस्थितं सर्वदा सन्नगेहम् ।
                      परब्रह्मब्रह्मादिभिर्वन्ध्यमानं शिवं शङ्करं शम्भुमीशानमीडे ॥ ५॥\n
                      कपालं त्रिशूलं कराभ्यां दधानं पदाम्भोजनम्राय कामं ददानम् ।
                      बलीवर्दयानं सुराणां प्रधानं शिवं शङ्करं शम्भुमीशानमीडे ॥ ६॥\n
                      शरच्चन्द्रगात्रं गुणानन्द पात्रं त्रिनेत्रं पवित्रं धनेशस्य मित्रम् ।
                      अपर्णाकलत्रं चरित्रं विचित्रं शिवं शङ्करं शम्भुमीशानमीडे ॥ ७॥\n
                      हरं सर्पहारं चिता भूविहारं भवं वेदसारं सदा निर्विकारम् ।
                      श्मशाने वसन्तं मनोजं दहन्तं शिवं शङ्करं शम्भुमीशानमीडे ॥ ८॥\n
                      स्तवं यः प्रभाते नरः शूलपाणे पठेत् सर्वदा भर्गभावानुरक्तः ।
                      स पुत्रं धनं धान्यमित्रं कलत्रं विचित्रं समासाद्य मोक्षं प्रयाति ॥ ९॥\n
                      ॥ इति शिवाष्टकं सम्पूर्णम् ॥`
    },
    {
        title:"॥ श्री शिवरामाष्टकस्तोत्रम् ॥",
        description: `शिवहरे शिवराम सखे प्रभो,त्रिविधताप-निवारण हे विभो।
                     अज जनेश्वर यादव पाहि मां,शिव हरे विजयं कुरू मे वरम्॥1॥\n
                     कमल लोचन राम दयानिधे,हर गुरो गजरक्षक गोपते।
                     शिवतनो भव शङ्कर पाहिमां,शिव हरे विजयं कुरू मे वरम्॥2॥\n
                     स्वजनरञ्जन मङ्गलमन्दिर,भजति तं पुरुषं परं पदम्।
                     भवति तस्य सुखं परमाद्भुतं,शिवहरे विजयं कुरू मे वरम्॥3॥\n
                     जय युधिष्ठिर-वल्लभ भूपते,जय जयार्जित-पुण्यपयोनिधे।
                     जय कृपामय कृष्ण नमोऽस्तुते,शिव हरे विजयं कुरू मे वरम्॥4॥\n
                     भवविमोचन माधव मापते,सुकवि-मानस हंस शिवारते।
                     जनक जारत माधव रक्षमां,शिव हरे विजयं कुरू मे वरम्॥5॥\n
                     अवनि-मण्डल-मङ्गल मापते,जलद सुन्दर राम रमापते।
                     निगम-कीर्ति-गुणार्णव गोपते,शिव हरे विजयं कुरू मे वरम्॥6॥\n
                     पतित-पावन-नाममयी लता,तव यशो विमलं परिगीयते।
                     तदपि माधव मां किमुपेक्षसे,शिव हरे विजयं कुरू मे वरम्॥7॥\n
                     अमर तापर देव रमापते,विनयतस्तव नाम धनोपमम्।
                     मयि कथं करुणार्णव जायते,शिव हरे विजयं कुरू मे वरम्॥8॥\n
                     हनुमतः प्रिय चाप कर प्रभो,सुरसरिद्-धृतशेखर हे गुरो।
                     मम विभो किमु विस्मरणं कृतं,शिव हरे विजयं कुरू मे वरम्॥9॥\n
                     नर हरेति परम् जन सुन्दरं,पठति यः शिवरामकृतस्तवम्।
                     विशति राम-रमा चरणाम्बुजे,शिव हरे विजयं कुरू मे वरम्॥10॥\n
                     प्रातरूथाय यो भक्त्या पठदेकाग्रमानसः।
                     विजयो जायते तस्य विष्णु सान्निध्यमाप्नुयात्॥11॥\n
                    ॥ इति श्रीरामानन्दस्वामिना विरचितं श्रीशिवरामाष्टकं सम्पूर्णम् ॥`
    },
    {
        title:"॥ द्वादश ज्योतिर्लिंग स्तोत्रम् ॥",
        description:`सौराष्ट्रदेशे विशदेऽतिरम्ये ज्योतिर्मयं चन्द्रकलावतंसम् ।
                     भक्तिप्रदानाय कृपावतीर्णं तं सोमनाथं शरणं प्रपद्ये ॥\n
                     श्रीशैलशृङ्गे विबुधातिसङ्गे तुलाद्रितुङ्गेऽपि मुदा वसन्तम् ।
                     तमर्जुनं मल्लिकपूर्वमेकं नमामि संसारसमुद्रसेतुम् ॥\n
                     अवन्तिकायां विहितावतारं मुक्तिप्रदानाय च सज्जनानाम् ।
                     अकालमृत्योः परिरक्षणार्थं वन्दे महाकालमहासुरेशम् ॥\n
                     कावेरिकानर्मदयोः पवित्रे समागमे सज्जनतारणाय ।
                     सदैवमान्धातृपुरे वसन्तमोङ्कारमीशं शिवमेकमीडे ॥\n
                     पूर्वोत्तरे प्रज्वलिकानिधाने सदा वसन्तं गिरिजासमेतम् ।
                     सुरासुराराधितपादपद्मं श्रीवैद्यनाथं तमहं नमामि ॥\n
                     याम्ये सदङ्गे नगरेऽतिरम्ये विभूषिताङ्गं विविधैश्च भोगैः ।
                     सद्भक्तिमुक्तिप्रदमीशमेकं श्रीनागनाथं शरणं प्रपद्ये ॥\n
                     महाद्रिपार्श्वे च तटे रमन्तं सम्पूज्यमानं सततं मुनीन्द्रैः ।
                     सुरासुरैर्यक्ष महोरगाढ्यैः केदारमीशं शिवमेकमीडे ॥\n
                     सह्याद्रिशीर्षे विमले वसन्तं गोदावरितीरपवित्रदेशे ।
                     यद्धर्शनात्पातकमाशु नाशं प्रयाति तं त्र्यम्बकमीशमीडे ॥\n
                     सुताम्रपर्णीजलराशियोगे निबध्य सेतुं विशिखैरसंख्यैः ।
                     श्रीरामचन्द्रेण समर्पितं तं रामेश्वराख्यं नियतं नमामि ॥\n
                     यं डाकिनिशाकिनिकासमाजे निषेव्यमाणं पिशिताशनैश्च ।
                     सदैव भीमादिपदप्रसिद्दं तं शङ्करं भक्तहितं नमामि ॥\n
                     सानन्दमानन्दवने वसन्तमानन्दकन्दं हतपापवृन्दम् ।
                     वाराणसीनाथमनाथनाथं श्रीविश्वनाथं शरणं प्रपद्ये ॥\n
                     इलापुरे रम्यविशालकेऽस्मिन् समुल्लसन्तं च जगद्वरेण्यम् ।
                     वन्दे महोदारतरस्वभावं घृष्णेश्वराख्यं शरणम् प्रपद्ये ॥\n
                     ज्योतिर्मयद्वादशलिङ्गकानां शिवात्मनां प्रोक्तमिदं क्रमेण ।
                     स्तोत्रं पठित्वा मनुजोऽतिभक्त्या फलं तदालोक्य निजं भजेच्च ॥`
    },
    {
        title: "॥ कालभैरवाष्टकम् ॥",
        description:`देवराजसेव्यमानपावनांघ्रिपङ्कजं व्यालयज्ञसूत्रमिन्दुशेखरं कृपाकरम् ।
                     नारदादियोगिवृन्दवन्दितं दिगंबरं काशिकापुराधिनाथकालभैरवं भजे ॥१॥\n
                     भानुकोटिभास्वरं भवाब्धितारकं परं नीलकण्ठमीप्सितार्थदायकं त्रिलोचनम् ।
                     कालकालमंबुजाक्षमक्षशूलमक्षरं काशिकापुराधिनाथकालभैरवं भजे ॥२॥\n
                     शूलटङ्कपाशदण्डपाणिमादिकारणं श्यामकायमादिदेवमक्षरं निरामयम् ।
                     भीमविक्रमं प्रभुं विचित्रताण्डवप्रियं काशिकापुराधिनाथकालभैरवं भजे ॥३॥\n
                     भुक्तिमुक्तिदायकं प्रशस्तचारुविग्रहं भक्तवत्सलं स्थितं समस्तलोकविग्रहम् ।
                     विनिक्वणन्मनोज्ञहेमकिङ्किणीलसत्कटिं काशिकापुराधिनाथकालभैरवं भजे ॥४॥\n
                     धर्मसेतुपालकं त्वधर्ममार्गनाशकं कर्मपाशमोचकं सुशर्मदायकं विभुम् ।
                     स्वर्णवर्णशेषपाशशोभिताङ्गमण्डलं काशिकापुराधिनाथकालभैरवं भजे ॥५॥\n
                     रत्नपादुकाप्रभाभिरामपादयुग्मकं नित्यमद्वितीयमिष्टदैवतं निरंजनम् ।
                     मृत्युदर्पनाशनं करालदंष्ट्रमोक्षणं काशिकापुराधिनाथकालभैरवं भजे ॥६॥\n
                     अट्टहासभिन्नपद्मजाण्डकोशसंततिं दृष्टिपातनष्टपापजालमुग्रशासनम् ।
                     अष्टसिद्धिदायकं कपालमालिकाधरं काशिकापुराधिनाथकालभैरवं भजे ॥७॥\n
                     भूतसंघनायकं विशालकीर्तिदायकं काशिवासलोकपुण्यपापशोधकं विभुम् ।
                     नीतिमार्गकोविदं पुरातनं जगत्पतिं काशिकापुराधिनाथकालभैरवं भजे ॥८॥\n
                     कालभैरवाष्टकं पठंति ये मनोहरं ज्ञानमुक्तिसाधनं विचित्रपुण्यवर्धनम् ।
                     शोकमोहदैन्यलोभकोपतापनाशनं प्रयान्ति कालभैरवांघ्रिसन्निधिं नरा ध्रुवम् ॥९॥\n
                     इति कालभैरवाष्टकम् सम्पूर्णम् ।\n`
    },
    {
        title: "॥ महाकालभैरवाष्टकम् ॥",
        description: `यं यं यं यक्षरूपं दशदिशिविदितं भूमिकम्पायमानं
                    सं सं संहारमूर्तिं शिरमुकुटजटा शेखरंचन्द्रबिम्बम् ।\n
                    दं दं दं दीर्घकायं विक्रितनख मुखं चोर्ध्वरोमं करालं
                    पं पं पं पापनाशं प्रणमत सततं भैरवं क्षेत्रपालम् ॥ १॥\n
                    रं रं रं रक्तवर्णं, कटिकटिततनुं तीक्ष्णदंष्ट्राकरालं
                    घं घं घं घोष घोषं घ घ घ घ घटितं घर्झरं घोरनादम् ।\n
                    कं कं कं कालपाशं द्रुक् द्रुक् दृढितं ज्वालितं कामदाहं
                    तं तं तं दिव्यदेहं, प्रणामत सततं, भैरवं क्षेत्रपालम् ॥ २॥\n
                    
                    लं लं लं लं वदन्तं ल ल ल ल ललितं दीर्घ जिह्वा करालं
                    धूं धूं धूं धूम्रवर्णं स्फुट विकटमुखं भास्करं भीमरूपम् ।\n
                    रुं रुं रुं रूण्डमालं, रवितमनियतं ताम्रनेत्रं करालम्
                    नं नं नं नग्नभूषं , प्रणमत सततं, भैरवं क्षेत्रपालम् ॥ ३॥\n
                    
                    वं वं वं वायुवेगं नतजनसदयं ब्रह्मसारं परन्तं
                    खं खं खं खड्गहस्तं त्रिभुवनविलयं भास्करं भीमरूपम् ।\n
                    चं चं चं चलित्वाऽचल चल चलिता चालितं भूमिचक्रं
                    मं मं मं मायि रूपं प्रणमत सततं भैरवं क्षेत्रपालम् ॥ ४॥\n
                    
                    शं शं शं शङ्खहस्तं, शशिकरधवलं, मोक्ष सम्पूर्ण तेजं
                    मं मं मं मं महान्तं, कुलमकुलकुलं मन्त्रगुप्तं सुनित्यम् ।\n
                    यं यं यं भूतनाथं, किलिकिलिकिलितं बालकेलिप्रदहानं
                    आं आं आं आन्तरिक्षं , प्रणमत सततं, भैरवं क्षेत्रपालम् ॥ ५॥\n
                    
                    खं खं खं खड्गभेदं, विषममृतमयं कालकालं करालं
                    क्षं क्षं क्षं क्षिप्रवेगं, दहदहदहनं, तप्तसन्दीप्यमानम् । \n
                    हौं हौं हौंकारनादं, प्रकटितगहनं गर्जितैर्भूमिकम्पं
                    बं बं बं बाललीलं, प्रणमत सततं, भैरवं क्षेत्रपालम् ॥ ६॥\n
                    
                    सं सं सं सिद्धियोगं, सकलगुणमखं, देवदेवं प्रसन्नं
                    पं पं पं पद्मनाभं, हरिहरमयनं चन्द्रसूर्याग्नि नेत्रम् ।\n
                    ऐं ऐं ऐं ऐश्वर्यनाथं, सततभयहरं, पूर्वदेवस्वरूपं
                    रौं रौं रौं रौद्ररूपं, प्रणमत सततं, भैरवं क्षेत्रपालम् ॥ ७॥\n
                    
                    हं हं हं हंसयानं, हसितकलहकं, मुक्तयोगाट्टहासं, 
                    धं धं धं नेत्ररूपं, शिरमुकुटजटाबन्ध बन्धाग्रहस्तम् ।\n
                    तं तं तं तंकानादं, त्रिदशलटलटं, कामगर्वापहारं,
                    भ्रुं भ्रुं भ्रुं भूतनाथं, प्रणमत सततं, भैरवं क्षेत्रपालम् ॥ ८॥\n

                    इति महाकालभैरवाष्टकं सम्पूर्णम् ।\n

                    नमो भूतनाथं नमो प्रेतनाथं नमः कालकालं नमः रुद्रमालम् ।
                    नमः कालिकाप्रेमलोलं करालं नमो भैरवं काशिकाक्षेत्रपालम् ॥`
    },
    {
        title: "॥ श्री भगवती स्तोत्रम् ॥",
        description: `जय भगवति देवी नमो वरदे, जय पापविनाशिनी बहुफलदे ।
                      जय शुम्भनिशुम्भकपालधरे, प्रणमामि तु देवी नरार्तिहरे ।।1।।\n
                      जय चन्द्रदिवाकरनेत्रधरे, जय पावकभूषितवक्त्रवरे ।
                      जय भैरवदेहनिलीन हरे, जय अंधकदैत्यविशोषकरे ।।2।।\n
                      जय महिषविमर्दिनि शूलकरे, जय लोकसमस्तकपापहरे ।
                      जय भगवति देवी नमो वरदे, जय पापविनाशिनी बहुफलदे ।।3।।\n
                      जय षण्मुखसायुधईशनुते, जय सागरगामिनि शम्भुनुते ।
                      जय दुःखदरिद्रविनाशकरे, जय पुत्रकलत्रविवृद्धिकरे ।।4।।\n
                      जय भगवति देवी नमो वरदे, जय पापविनाशिनी बहुफलदे ।
                      जय देवि समस्तशरीरधरे, जय नाकविदर्शिनि दुःखहरे ।।5।।\n
                      जय व्याधिविनाशिनि मोक्षकरे, जय वांछितदायिनि सिद्धिवरे ।
                      जय भगवति देवी नमो वरदे, जय पापविनाशिनी बहुफलदे ।। 6।।\n
                      एतद्व्यासकृतं स्तोत्रं य: पठेन्नियत: शुचि: ।
                      ग्रहे वा शुद्धभावेन प्रीता भगवती सदा ।।7।।`
    },
    {
        title: "॥ श्री विन्ध्येश्वरी स्तोत्रम् ॥",
        description:`निशुम्भ शुम्भ गर्जनी, प्रचण्ड मुण्ड खण्डिनी ।
                     बनेरणे प्रकाशिनी, भजामि विन्ध्यवासिनी ॥ \n
                     त्रिशूल मुण्ड धारिणी, धरा विघात हारिणी ।
                     गृहे-गृहे निवासिनी, भजामि विन्ध्यवासिनी ॥\n
                     दरिद्र दुःख हारिणी, सदा विभूति कारिणी ।
                     वियोग शोक हारिणी, भजामि विन्ध्यवासिनी ॥\n
                     लसत्सुलोल लोचनं, लतासनं वरप्रदं ।
                     कपाल-शूल धारिणी, भजामि विन्ध्यवासिनी ॥\n
                     कराब्जदानदाधरां, शिवाशिवां प्रदायिनी ।
                     वरा-वराननां शुभां, भजामि विन्ध्यवासिनी ॥\n
                     कपीन्द्न जामिनीप्रदां, त्रिधा स्वरूप धारिणी ।
                     जले-थले निवासिनी, भजामि विन्ध्यवासिनी ॥\n
                     विशिष्ट शिष्ट कारिणी, विशाल रूप धारिणी ।
                     महोदरे विलासिनी, भजामि विन्ध्यवासिनी ॥\n
                     पुंरदरादि सेवितां, पुरादिवंशखण्डितम्‌ ।
                     विशुद्ध बुद्धिकारिणीं, भजामि विन्ध्यवासिनीं ॥`
    },
    {
        title: "॥ महिषासुरमर्दिनि स्तोत्रम् ॥",
        description:`अयि गिरिनन्दिनि नन्दितमेदिनि विश्वविनोदिनि नन्दिनुते
                    गिरिवरविन्ध्यशिरोऽधिनिवासिनि विष्णुविलासिनि जिष्णुनुते ।
                    भगवति हे शितिकण्ठकुटुम्बिनि भूरिकुटुम्बिनि भूरिकृते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १ ॥\n
                    सुरवरवर्षिणि दुर्धरधर्षिणि दुर्मुखमर्षिणि हर्षरते
                    त्रिभुवनपोषिणि शङ्करतोषिणि किल्बिषमोषिणि घोषरते
                    दनुजनिरोषिणि दितिसुतरोषिणि दुर्मदशोषिणि सिन्धुसुते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ २ ॥\n
                    अयि जगदम्ब मदम्ब कदम्ब वनप्रियवासिनि हासरते
                    शिखरि शिरोमणि तुङ्गहिमलय शृङ्गनिजालय मध्यगते ।
                    मधुमधुरे मधुकैटभगञ्जिनि कैटभभञ्जिनि रासरते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ३ ॥\n
                    अयि शतखण्ड विखण्डितरुण्ड वितुण्डितशुण्द गजाधिपते
                    रिपुगजगण्ड विदारणचण्ड पराक्रमशुण्ड मृगाधिपते ।
                    निजभुजदण्ड निपातितखण्ड विपातितमुण्ड भटाधिपते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ४ ॥\n
                    अयि रणदुर्मद शत्रुवधोदित दुर्धरनिर्जर शक्तिभृते
                    चतुरविचार धुरीणमहाशिव दूतकृत प्रमथाधिपते ।
                    दुरितदुरीह दुराशयदुर्मति दानवदुत कृतान्तमते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ५ ॥\n
                    अयि शरणागत वैरिवधुवर वीरवराभय दायकरे
                    त्रिभुवनमस्तक शूलविरोधि शिरोऽधिकृतामल शूलकरे ।
                    दुमिदुमितामर धुन्दुभिनादमहोमुखरीकृत दिङ्मकरे
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ६ ॥\n
                    अयि निजहुङ्कृति मात्रनिराकृत धूम्रविलोचन धूम्रशते
                    समरविशोषित शोणितबीज समुद्भवशोणित बीजलते ।
                    शिवशिवशुम्भ निशुम्भमहाहव तर्पितभूत पिशाचरते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ७ ॥\n
                    धनुरनुषङ्ग रणक्षणसङ्ग परिस्फुरदङ्ग नटत्कटके
                    कनकपिशङ्ग पृषत्कनिषङ्ग रसद्भटशृङ्ग हताबटुके ।
                    कृतचतुरङ्ग बलक्षितिरङ्ग घटद्बहुरङ्ग रटद्बटुके
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ८ ॥\n
                    सुरललना ततथेयि तथेयि कृताभिनयोदर नृत्यरते
                    कृत कुकुथः कुकुथो गडदादिकताल कुतूहल गानरते ।
                    धुधुकुट धुक्कुट धिंधिमित ध्वनि धीर मृदंग निनादरते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ९ ॥\n
                    जय जय जप्य जयेजयशब्द परस्तुति तत्परविश्वनुते
                    झणझणझिञ्झिमि झिङ्कृत नूपुरशिञ्जितमोहित भूतपते ।
                    नटित नटार्ध नटी नट नायक नाटितनाट्य सुगानरते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १० ॥\n
                    अयि सुमनःसुमनःसुमनः सुमनःसुमनोहरकान्तियुते
                    श्रितरजनी रजनीरजनी रजनीरजनी करवक्त्रवृते ।
                    सुनयनविभ्रमर भ्रमरभ्रमर भ्रमरभ्रमराधिपते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ ११ ॥\n
                    सहितमहाहव मल्लमतल्लिक मल्लितरल्लक मल्लरते
                    विरचितवल्लिक पल्लिकमल्लिक झिल्लिकभिल्लिक वर्गवृते ।
                    शितकृतफुल्ल समुल्लसितारुण तल्लजपल्लव सल्ललिते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १२ ॥\n
                    अविरलगण्ड गलन्मदमेदुर मत्तमतङ्ग जराजपते
                    त्रिभुवनभुषण भूतकलानिधि रूपपयोनिधि राजसुते ।
                    अयि सुदतीजन लालसमानस मोहन मन्मथराजसुते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १३ ॥\n
                    कमलदलामल कोमलकान्ति कलाकलितामल भाललते
                    सकलविलास कलानिलयक्रम केलिचलत्कल हंसकुले ।
                    अलिकुलसङ्कुल कुवलयमण्डल मौलिमिलद्बकुलालिकुले
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १४ ॥\n
                    करमुरलीरव वीजितकूजित लज्जितकोकिल मञ्जुमते
                    मिलितपुलिन्द मनोहरगुञ्जित रञ्जितशैल निकुञ्जगते ।
                    निजगणभूत महाशबरीगण सद्गुणसम्भृत केलितले
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १५ ॥\n
                    कटितटपीत दुकूलविचित्र मयुखतिरस्कृत चन्द्ररुचे
                    प्रणतसुरासुर मौलिमणिस्फुर दंशुलसन्नख चन्द्ररुचे
                    जितकनकाचल मौलिमदोर्जित निर्भरकुञ्जर कुम्भकुचे
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १६ ॥\n
                    विजितसहस्रकरैक सहस्रकरैक सहस्रकरैकनुते
                    कृतसुरतारक सङ्गरतारक सङ्गरतारक सूनुसुते ।
                    सुरथसमाधि समानसमाधि समाधिसमाधि सुजातरते ।
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १७ ॥\n
                    पदकमलं करुणानिलये वरिवस्यति योऽनुदिनं सुशिवे
                    अयि कमले कमलानिलये कमलानिलयः स कथं न भवेत् ।
                    तव पदमेव परम्पदमित्यनुशीलयतो मम किं न शिवे
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १८ ॥\n
                    कनकलसत्कलसिन्धुजलैरनुषिञ्चति तेगुणरङ्गभुवम्
                    भजति स किं न शचीकुचकुम्भतटीपरिरम्भसुखानुभवम् ।
                    तव चरणं शरणं करवाणि नतामरवाणि निवासि शिवम्
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ १९ ॥\n
                    तव विमलेन्दुकुलं वदनेन्दुमलं सकलं ननु कूलयते
                    किमु पुरुहूतपुरीन्दु मुखी सुमुखीभिरसौ विमुखीक्रियते ।
                    मम तु मतं शिवनामधने भवती कृपया किमुत क्रियते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ २० ॥\n
                    अयि मयि दीन दयालुतया कृपयैव त्वया भवितव्यमुमे
                    अयि जगतो जननी कृपयासि यथासि तथानुमितासिरते ।
                    यदुचितमत्र भवत्युररीकुरुतादुरुतापमपाकुरुते
                    जय जय हे महिषासुरमर्दिनि रम्यकपर्दिनि शैलसुते ॥ २१ ॥\n`
    },
    {
        title: "॥ भवान्यष्टकम्न ॥",
        description:`न तातो न माता न बन्धुर्न दाता न पुत्रो न पुत्री न भृत्यो न भर्ता ।
                    न जाया न विद्या न वृत्तिर्ममैव गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥१॥\n
                    भवाब्धावपारे महादुःखभीरु पपात प्रकामी प्रलोभी प्रमत्तः ।
                    कुसंसारपाशप्रबद्धः सदाहं गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥२॥\n
                    न जानामि दानं न च ध्यानयोगं न जानामि तन्त्रं न च स्तोत्रमन्त्रम् ।
                    न जानामि पूजां न च न्यासयोगं गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥३॥\n
                    न जानामि पुण्यं न जानामि तीर्थ न जानामि मुक्तिं लयं वा कदाचित् ।
                    न जानामि भक्तिं व्रतं वापि मातर्गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥४॥\n
                    कुकर्मी कुसङ्गी कुबुद्धिः कुदासः कुलाचारहीनः कदाचारलीनः ।
                    कुदृष्टिः कुवाक्यप्रबन्धः सदाहं गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥५॥\n
                    प्रजेशं रमेशं महेशं सुरेशं दिनेशं निशीथेश्वरं वा कदाचित् ।
                    न जानामि चान्यत् सदाहं शरण्ये गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥६॥\n
                    विवादे विषादे प्रमादे प्रवासे जले चानले पर्वते शत्रुमध्ये ।
                    अरण्ये शरण्ये सदा मां प्रपाहि गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥७॥\n
                    अनाथो दरिद्रो जरारोगयुक्तो महाक्षीणदीनः सदा जाड्यवक्त्रः ।
                    विपत्तौ प्रविष्टः प्रनष्टः सदाहं गतिस्त्वं गतिस्त्वं त्वमेका भवानि ॥८॥`
    },
    {
        title: "॥ देव्यपराधक्षमापन स्तोत्रम् ॥",
        description:`न मत्रं नो यन्त्रं तदपि च न जाने स्तुतिमहो न चाह्वानं ध्यानं तदपि च न जाने स्तुतिकथाः।
                     न जाने मुद्रास्ते तदपि च न जाने विलपनं परं जाने मातस्त्वदनुसरणं क्लेशहरणम् ॥१॥\n
                     विधेरज्ञानेन द्रविणविरहेणालसतया विधेयाशक्यत्वात्तव चरणयोर्या च्युतिरभूत् ।
                     तदेतत् क्षन्तव्यं जननि सकलोद्धारिणि शिवे कुपुत्रो जायेत क्वचिदपि कुमाता न भवति ॥२॥\n
                     पृथिव्यां पुत्रास्ते जननि बहवः सन्ति सरलाः परं तेषां मध्ये विरलतरलोऽहं तव सुतः ।
                     मदीयोऽयं त्यागः समुचितमिदं नो तव शिवे कुपुत्रो जायेत क्वचिदपि कुमाता न भवति ॥३॥\n
                     जगन्मातर्मातस्तव चरणसेवा न रचिता न वा दत्तं देवि द्रविणमपि भूयस्तव मया ।
                     तथापि त्वं स्नेहं मयि निरुपमं यत्प्रकुरुषे कुपुत्रो जायेत क्वचिदपि कुमाता न भवति ॥४॥\n
                     परित्यक्ता देवा विविधविधसेवाकुलतया मया पञ्चाशीतेरधिकमपनीते तु वयसि ।
                     इदानीं चेन्मातस्तव यदि कृपा नापि भविता निरालम्बो लम्बोदरजननि कं यामि शरणम् ॥५॥\n
                     श्वपाको जल्पाको भवति मधुपाकोपमगिरा निरातङ्को रङ्को विहरति चिरं कोटिकनकैः ।
                     तवापर्णे कर्णे विशति मनुवर्णे फलमिदं जनः को जानीते जननि जपनीयं जपविधौ ॥६॥\n
                     चिताभस्मालेपो गरलमशनं दिक्पटधरो जटाधारी कण्ठे भुजगपतिहारी पशुपतिः ।
                     कपाली भूतेशो भजति जगदीशैकपदवीं भवानि त्वत्पाणिग्रहणपरिपाटीफलमिदम् ॥७॥\n
                     न मोक्षस्याकाङ्क्षा भवविभववाञ्छापि च न मे न विज्ञानापेक्षा शशिमुखि सुखेच्छापि न पुनः ।
                     अतस्त्वां संयाचे जननि जननं यातु मम वै मृडानी रुद्राणी शिव शिव भवानीति जपतः ॥८॥\n
                     नाराधितासि विधिना विविधोपचारैः किं रुक्षचिन्तनपरैर्न कृतं वचोभिः ।
                     श्यामे त्वमेव यदि किञ्चन मय्यनाथे धत्से कृपामुचितमम्ब परं तवैव ॥९॥\n
                     आपत्सु मग्नः स्मरणं त्वदीयं करोमि दुर्गे करुणार्णवेशि ।
                     नैतच्छठत्वं मम भावयेथाः क्षुधातृषार्ता जननीं स्मरन्ति ॥१०॥\n
                     जगदम्ब विचित्रमत्र किं परिपूर्णा करुणास्ति चेन्मयि ।
                     अपराधपरम्परापरं न हि माता समुपेक्षते सुतम् ॥ ११॥\n
                     मत्समः पातकी नास्ति पापघ्नी त्वत्समा न हि।
                     एवं ज्ञात्वा महादेवि यथायोग्यं तथा कुरु ॥१२॥`
    },
    {
        title: "॥ मधुराष्टकम् ॥",
        description:`अधरं मधुरं वदनं मधुरं, नयनं मधुरं हसितं मधुरम् ।
                    हृदयं मधुरं, गमनं मधुरं, मधुराधिपतेरखिलं मधुरम् ।।१।।\n

                    वसनं मधुरं, चरितं मधुरं, वचनं मधुरं वलितं मधुरम्,
                    चलितं मधुरं, भ्रमितं मधुरं, मधुराधिपतेरखिलं मधुरम्।।२।।\n

                    वेणर्मधुरो रेणुर्मधुरः पाणिर्मधुरः पादौ मधुरौ,
                    नृत्यं मधुरं सख्यं मधुरं मधुराधिपतेरखिलं मधुरम् ।।३।।\n

                    गीतं मधुरं पीतं मधुरं, भुक्तं मधुरं सुप्तं मधुरम्,
                    रूपं मधुरं तिलकं मधुरं मधुराधिपतेरखिलं मधुरम् ।।४।।\n

                    करणं मधुरं, तरणं मधुरं, हरणं मधुरं, रमणं मधुरम्,
                    वमितं मधुरं, शमितं मधुरं, मधुराधिपतेरखिलं मधुरम् ।।५।।\n

                    गुञ्जा मधुरा माला मधुरा यमुना मधुरा वीची मधुरा,
                    सलिलं मधुरं, कमलं मधुरं मधुराधिपतेरखिलं मधुरम् ।।६।।\n

                    गोपी मधुरा लीला मधुरा, राधा मधुरा मिलनं मधुरम्,
                    दृष्टं मधुरं शिष्टं मधुरं मधुराधिपतेरखिलं मधुरम् ।।७।।\n

                    गोपा मधुरा गावो मधुरा, यष्टिर्मधुरा सृष्टिर्मधुरा,
                    दलितं मधुरं, फलितं मधुरं, मधुराधिपतेरखिलं मधुरम् ।।८।।\n

                    || इति श्रीमद्वल्लाभाचार्य विरचित मधुराष्टकं संपूर्णं || || श्रीकृष्णार्पणमस्तु ||`
    },
    {
        title: "॥ भज गोविन्दम् ॥",
        description:`भज गोविन्दम् भज गोविन्दम्, गोविन्दं भज मूढ़मते।
                     संप्राप्ते सन्निहिते काले, न हि न हि रक्षति डुकृञ् करणे॥१॥\n
                     मूढ़ जहीहि धनागमतृष्णाम्, कुरु सद्बुद्धिमं मनसि वितृष्णाम्।
                     यल्लभसे निजकर्मोपात्तम्, वित्तं तेन विनोदय चित्तं॥२॥\n
                     नारीस्तनभरनाभीदेशम्, दृष्ट्वा मागा मोहावेशम्।
                     एतन्मान्सवसादिविकारम्, मनसि विचिन्तय वारं वारम्॥३॥\n
                     नलिनीदलगतजलमतितरलम्, तद्वज्जीवितमतिशयचपलम्।
                     विद्धि व्याध्यभिमानग्रस्तं, लोक शोकहतं च समस्तम्॥४॥\n
                     यावद्वित्तोपार्जनसक्त:, तावन्निजपरिवारो रक्तः।
                     पश्चाज्जीवति जर्जरदेहे, वार्तां कोऽपि न पृच्छति गेहे॥५॥\n
                     यावत्पवनो निवसति देहे, तावत् पृच्छति कुशलं गेहे।
                     गतवति वायौ देहापाये, भार्या बिभ्यति तस्मिन्काये॥६॥\n
                     बालस्तावत् क्रीडासक्तः, तरुणस्तावत् तरुणीसक्तः।
                     वृद्धस्तावच्चिन्तासक्तः, परे ब्रह्मणि कोऽपि न सक्तः॥७॥\n
                     का ते कांता कस्ते पुत्रः, संसारोऽयमतीव विचित्रः।
                     कस्य त्वं वा कुत अयातः, तत्त्वं चिन्तय तदिह भ्रातः॥८॥\n
                     सत्संगत्वे निस्संगत्वं, निस्संगत्वे निर्मोहत्वं।
                     निर्मोहत्वे निश्चलतत्त्वं निश्चलतत्त्वे जीवन्मुक्तिः॥९॥\n
                     व यसि गते कः कामविकारः, शुष्के नीरे कः कासारः।
                     क्षीणे वित्ते कः परिवारः, ज्ञाते तत्त्वे कः संसारः॥१०॥\n
                     मा कुरु धनजनयौवनगर्वं, हरति निमेषात्कालः सर्वं।
                     मायामयमिदमखिलम् हित्वा, ब्रह्मपदम् त्वं प्रविश विदित्वा॥११॥\n
                     दिनयामिन्यौ सायं प्रातः, शिशिरवसन्तौ पुनरायातः।
                     कालः क्रीडति गच्छत्यायुस्तदपि न मुन्च्त्याशावायुः॥१२॥\n
                     द्वादशमंजरिकाभिरशेषः कथितो वैयाकरणस्यैषः।
                     उपदेशोऽभूद्विद्यानिपुणैः, श्रीमच्छंकरभगवच्चरणैः॥१२॥\n
                     काते कान्ता धन गतचिन्ता, वातुल किं तव नास्ति नियन्ता।
                     त्रिजगति सज्जनसं गतिरैका, भवति भवार्णवतरणे नौका॥१३॥\n
                     जटिलो मुण्डी लुञ्छितकेशः, काषायाम्बरबहुकृतवेषः।
                     पश्यन्नपि च न पश्यति मूढः, उदरनिमित्तं बहुकृतवेषः॥१४॥\n
                     अङ्गं गलितं पलितं मुण्डं, दशनविहीनं जतं तुण्डम्।
                     वृद्धो याति गृहीत्वा दण्डं, तदपि न मुञ्चत्याशापिण्डम्॥१५॥\n
                     अग्रे वह्निः पृष्ठेभानुः, रात्रौ चुबुकसमर्पितजानुः।
                     करतलभिक्षस्तरुतलवासः, तदपि न मुञ्चत्याशापाशः॥१६॥\n
                     कुरुते गङ्गासागरगमनं, व्रतपरिपालनमथवा दानम्।
                     ज्ञानविहिनः सर्वमतेन, मुक्तिं न भजति जन्मशतेन॥१७॥\n
                     सुर मंदिर तरु मूल निवासः, शय्या भूतल मजिनं वासः।
                     सर्व परिग्रह भोग त्यागः, कस्य सुखं न करोति विरागः॥१८॥\n
                     योगरतो वाभोगरतोवा, सङ्गरतो वा सङ्गवीहिनः।
                     यस्य ब्रह्मणि रमते चित्तं, नन्दति नन्दति नन्दत्येव॥१९॥\n
                     भगवद् गीता किञ्चिदधीता, गङ्गा जललव कणिकापीता।
                     सकृदपि येन मुरारि समर्चा, क्रियते तस्य यमेन न चर्चा॥२०॥\n
                     पुनरपि जननं पुनरपि मरणं, पुनरपि जननी जठरे शयनम्।
                     इह संसारे बहुदुस्तारे, कृपयाऽपारे पाहि मुरारे॥२१॥\n
                     रथ्या चर्पट विरचित कन्थः, पुण्यापुण्य विवर्जित पन्थः।
                     योगी योगनियोजित चित्तो, रमते बालोन्मत्तवदेव॥२२॥\n
                     कस्त्वं कोऽहं कुत आयातः, का मे जननी को मे तातः।
                     इति परिभावय सर्वमसारम्, विश्वं त्यक्त्वा स्वप्न विचारम्॥२३॥\n
                     त्वयि मयि चान्यत्रैको विष्णुः, व्यर्थं कुप्यसि मय्यसहिष्णुः।
                     भव समचित्तः सर्वत्र त्वं, वाञ्छस्यचिराद्यदि विष्णुत्वम्॥२४॥\n
                     शत्रौ मित्रे पुत्रे बन्धौ, मा कुरु यत्नं विग्रहसन्धौ।
                     सर्वस्मिन्नपि पश्यात्मानं, सर्वत्रोत्सृज भेदाज्ञानम्॥२५॥\n
                     कामं क्रोधं लोभं मोहं, त्यक्त्वाऽत्मानं भावय कोऽहम्।
                     आत्मज्ञान विहीना मूढाः, ते पच्यन्ते नरकनिगूढाः॥२६॥\n
                     गेयं गीता नाम सहस्रं, ध्येयं श्रीपति रूपमजस्रम्।
                     नेयं सज्जन सङ्गे चित्तं,देयं दीनजनाय च वित्तम्॥२७॥\n
                     सुखतः क्रियते रामाभोगः, पश्चाद्धन्त शरीरे रोगः।
                     यद्यपि लोके मरणं शरणं, तदपि न मुञ्चति पापाचरणम्॥२८॥\n
                     अर्थंमनर्थम् भावय नित्यं, नास्ति ततः सुखलेशः सत्यम्।
                     पुत्रादपि धनभजाम् भीतिः, सर्वत्रैषा विहिता रीतिः॥२९॥\n
                     प्राणायामं प्रत्याहारं, नित्यानित्य विवेकविचारम्।
                     जाप्यसमेत समाधिविधानं, कुर्ववधानं महदवधानम्॥३०॥\n
                     गुरुचरणाम्बुज निर्भर भक्तः, संसारादचिराद्भव मुक्तः।
                     सेन्द्रियमानस नियमादेवं, द्रक्ष्यसि निज हृदयस्थं देवम्॥३१॥\n
                     मूढः कश्चन वैयाकरणो, डुकृञ्करणाध्ययन धुरिणः।
                     श्रीमच्छम्कर भगवच्छिष्यै, बोधित आसिच्छोधितकरणः॥३२॥\n
                     भजगोविन्दं भजगोविन्दं, गोविन्दं भजमूढमते। 
                     नामस्मरणादन्यमुपायं, नहि पश्यामो भवतरणे॥३३॥\n
                     || श्री शङ्कराचार्य कृतं ||`
    },
    {
        title: "॥ श्री हरि स्तोत्रम् ॥",
        description:`जगज्जालपालं चलत्कण्ठमालंशरच्चन्द्रभालं महादैत्यकालं,
                     नभोनीलकायं दुरावारमायंसुपद्मासहायम् भजेऽहं भजेऽहं॥1॥\n
                     सदाम्भोधिवासं गलत्पुष्पहासंजगत्सन्निवासं शतादित्यभासं,
                     गदाचक्रशस्त्रं लसत्पीतवस्त्रंहसच्चारुवक्त्रं भजेऽहं भजेऽहं॥2॥\n
                     रमाकण्ठहारं श्रुतिव्रातसारंजलान्तर्विहारं धराभारहारं,
                     चिदानन्दरूपं मनोज्ञस्वरूपंध्रुतानेकरूपं भजेऽहं भजेऽहं॥3॥\n
                     जराजन्महीनं परानन्दपीनंसमाधानलीनं सदैवानवीनं,
                     जगज्जन्महेतुं सुरानीककेतुंत्रिलोकैकसेतुं भजेऽहं भजेऽहं॥4॥\n
                     कृताम्नायगानं खगाधीशयानंविमुक्तेर्निदानं हरारातिमानं,
                     स्वभक्तानुकूलं जगद्व्रुक्षमूलंनिरस्तार्तशूलं भजेऽहं भजेऽहं॥5॥\n
                     समस्तामरेशं द्विरेफाभकेशंजगद्विम्बलेशं ह्रुदाकाशदेशं,
                     सदा दिव्यदेहं विमुक्ताखिलेहंसुवैकुण्ठगेहं भजेऽहं भजेऽहं॥6॥\n
                     सुरालिबलिष्ठं त्रिलोकीवरिष्ठंगुरूणां गरिष्ठं स्वरूपैकनिष्ठं,
                     सदा युद्धधीरं महावीरवीरंमहाम्भोधितीरं भजेऽहं भजेऽहं॥7॥\n
                     रमावामभागं तलानग्रनागंकृताधीनयागं गतारागरागं,
                     मुनीन्द्रैः सुगीतं सुरैः संपरीतंगुणौधैरतीतं भजेऽहं भजेऽहं॥8॥\n
                     ॥ फलश्रुति ॥\n
                     इदं यस्तु नित्यं समाधाय चित्तंपठेदष्टकं कण्ठहारम् मुरारे:,
                     स विष्णोर्विशोकं ध्रुवं याति लोकंजराजन्मशोकं पुनर्विन्दते नो॥9॥`
    },
    {
        title: "॥ श्री रामाष्टकम् ॥",
        description:`भजे विशेषसुन्दरं समस्तपापखण्डनम् ।
                स्वभक्तचित्तरञ्जनं सदैव राममद्वयम् ॥ १ ॥\n
                जटाकलापशोभितं समस्तपापनाशकं ।
                स्वभक्तभीतिभञ्जनं भजे ह राममद्वयम् ॥ २ ॥\n
                निजस्वरूपबोधकं कृपाकरं भवापहम् ।
                समं शिवं निरञ्जनं भजे ह राममद्वयम् ॥ ३ ॥\n
                सहप्रपञ्चकल्पितं ह्यनामरूपवास्तवम् ।
                निराकृतिं निरामयं भजे ह राममद्वयम् ॥ ४ ॥\n
                निष्प्रपञ्चनिर्विकल्पनिर्मलं निरामयम् ।
                चिदेकरूपसन्ततं भजे ह राममद्वयम् ॥ ५ ॥\n
                भवाब्धिपोतरूपकं ह्यशेषदेहकल्पितम् ।
                गुणाकरं कृपाकरं भजे ह राममद्वयम् ॥ ६ ॥\n
                महावाक्यबोधकैर्विराजमानवाक्पदैः ।
                परं ब्रह्मसद्व्यापकं भजे ह राममद्वयम् ॥ ७ ॥\n
                शिवप्रदं सुखप्रदं भवच्छिदं भ्रमापहम् ।
                विराजमानदेशिकं भजे ह राममद्वयम् ॥ ८ ॥\n
                रामाष्टकं पठति यस्सुखदं सुपुण्यं
                व्यासेन भाषितमिदं शृणुते मनुष्यः
                विद्यां श्रियं विपुलसौख्यमनन्तकीर्तिं
                संप्राप्य देहविलये लभते च मोक्षम् ॥ ९ ॥\n

                ॥ इति श्रीव्यासविरचितं रामाष्टकं संपूर्णम् ॥
                `
    },
    {
        title: "॥ श्री राम स्तुति ॥",
        description:`नमामि भक्त वत्सलं कृपालु शील कोमलं
                     भजामि ते पदांबुजं अकामिनां स्वधामदं ॥\n
                     निकाम श्याम सुंदरं भवाम्बुनाथ मंदरं 
                     प्रफुल्ल कंज लोचनं मदादि दोष मोचनं ॥\n
                     प्रलंब बाहु विक्रमं प्रभोऽप्रमेय वैभवं
                     निषंग चाप सायकं धरं त्रिलोक नायकं ॥\n
                     दिनेश वंश मंडनं महेश चाप खंडनं
                     मुनींद्र संत रंजनं सुरारि वृन्द भंजनं ॥\n
                     मनोज वैरि वंदितं अजादि देव सेवितं
                     विशुद्ध बोध विग्रहं समस्त दूषणापहं ॥\n
                     नमामि इंदिरा पतिं सुखाकरं सतां गतिं
                     भजे सशक्ति सानुजं शची पति प्रियानुजं ॥\n
                     त्वदंघ्रि मूल ये नराः भजंति हीन मत्सराः
                     पतंति नो भवार्णवे वितर्क वीचि संकुले ॥\n
                     विविक्त वासिनः सदा भजंति मुक्तये मुदा
                     निरस्य इंद्रियादिकं प्रयांति ते गतिं स्वकं ॥\n
                     तमेकमद्भुतं प्रभुं निरीहमीश्वरं विभुं
                     जगद्गुरुं च शाश्वतं तुरीयमेव केवलं ॥\n
                     भजामि भाव वल्लभं कुयोगिनां सुदुर्लभं
                     स्वभक्त कल्प पादपं समं सुसेव्यमन्वहं ॥\n
                     अनूप रूप भूपतिं नतोऽहमुर्विजा पतिं
                     प्रसीद मे नमामि ते पदाब्ज भक्ति देहि मे ॥\n
                     पठंति ये स्तवं इदं नरादरेण ते पदं
                     व्रजंति नात्र संशयं वदीय भक्ति संयुताः ॥`
    },
    {
        title: "॥ श्री हनुमान चालीसा ॥",
        description:`श्रीगुरु चरन सरोज रज, निज मनु मुकुरु सुधारि
                    बरनऊं रघुबर बिमल जसु, जो दायकु फल चारि
                    बुद्धिहीन तनु जानिके, सुमिरौं पवन कुमार
                    बल बुद्धि बिद्या देहु मोहिं, हरहु कलेस बिकार \n
                    जय हनुमान ज्ञान गुन सागर, जय कपीस तिहुं लोक उजागर
                    रामदूत अतुलित बल धामा, अंजनि-पुत्र पवनसुत नामा \n
                    महाबीर बिक्रम बजरंगी, कुमति निवार सुमति के संगी
                    कंचन बरन बिराज सुबेसा, कानन कुण्डल कुंचित केसा \n
                    हाथ बज्र औ ध्वजा बिराजै, कांधे मूँज जनेउ साजे
                    शंकर सुवन केसरीनंदन, तेज प्रताप महा जग वंदन \n
                    बिद्यावान गुनी अति चातुर, राम काज करिबे को आतुर
                    प्रभु चरित्र सुनिबे को रसिया, राम लखन सीता मन बसिया \n
                    सूक्ष्म रूप धरि सियहिं दिखावा, बिकट रूप धरि लंक जरावा
                    भीम रूप धरि असुर संहारे, रामचंद्र के काज संवारे \n
                    लाय सजीवन लखन जियाये, श्री रघुबीर हरषि उर लाये
                    रघुपति कीन्ही बहुत बड़ाई, तुम मम प्रिय भरतहि सम भाई \n
                    सहस बदन तुम्हरो जस गावैं, अस कहि श्रीपति कण्ठ लगावैं
                    सनकादिक ब्रह्मादि मुनीसा, नारद सारद सहित अहीसा \n
                    जम कुबेर दिगपाल जहाँ ते, कबि कोबिद कहि सके कहाँ ते
                    तुम उपकार सुग्रीवहिं कीन्हा, राम मिलाय राज पद दीन्हा \n
                    तुम्हरो मंत्र बिभीषन माना, लंकेश्वर भए सब जग जाना
                    जुग सहस्र जोजन पर भानु, लील्यो ताहि मधुर फल जानू \n
                    प्रभु मुद्रिका मेलि मुख माहीं, जलधि लांघि गये अचरज नाहीं
                    दुर्गम काज जगत के जेते, सुगम अनुग्रह तुम्हरे तेते \n
                    राम दुआरे तुम रखवारे, होत न आज्ञा बिनु पैसारे
                    सब सुख लहै तुम्हारी सरना, तुम रक्षक काहू को डर ना \n
                    आपन तेज सम्हारो आपै, तीनों लोक हांक तें कांपै
                    भूत पिसाच निकट नहिं आवै, महाबीर जब नाम सुनावै \n
                    नासै रोग हरे सब पीरा, जपत निरंतर हनुमत बीरा
                    संकट तें हनुमान छुड़ावै, मन क्रम बचन ध्यान जो लावै \n
                    सब पर राम तपस्वी राजा, तिन के काज सकल तुम साजा
                    और मनोरथ जो कोई लावै, सोई अमित जीवन फल पावै \n
                    चारों जुग परताप तुम्हारा, है परसिद्ध जगत उजियारा
                    साधु-संत के तुम रखवारे, असुर निकंदन राम दुलारे \n
                    अष्ट सिद्धि नौ निधि के दाता, अस बर दीन जानकी माता
                    राम रसायन तुम्हरे पासा, सदा रहो रघुपति के दासा \n
                    तुम्हरे भजन राम को पावै, जनम-जनम के दुख बिसरावै
                    अन्तकाल रघुबर पुर जाई, जहाँ जन्म हरिभक्त कहाई \n
                    और देवता चित्त न धरई, हनुमत सेइ सर्ब सुख करई
                    संकट कटै मिटै सब पीरा, जो सुमिरै हनुमत बलबीरा \n
                    जय, जय, जय, हनुमान गोसाईं, कृपा करहु गुरुदेव की नाईं
                    जो सत बार पाठ कर कोई, छूटहि बंदि महा सुख होई \n
                    जो यह पढ़ै हनुमान चालीसा, होए सिद्धि साखी गौरीसा
                    तुलसीदास सदा हरि चेरा, कीजै नाथ हृदय मंह डेरा || \n
                    पवन तनय संकट हरन, मंगल मूरती रूप
                    राम लखन सीता सहित, हृदय बसहु सुर भूप ||`
    }
    
]